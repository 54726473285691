export const environment = {
    production: false,
    auth: {
        clientID: 'fUFYrScRTIv652pmyCR5rRtSOjxhC1NX',
        audience: 'https://media.ra.dev/rate-optics',
        domain: 'media-dev.auth0.com',
        algorithm: 'RSA256',
        responseType: 'token id_token',
        scope: 'openid profile email',
    },
    api: 'https://api.test.rateoptics.io',
    api2: 'https://api2.test.rateoptics.io',
};
